@font-face {
  font-family: 'Quiet Sans';
  src: url("../assets/fonts/Quiet Sans Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Quiet Sans';
  src: url("../assets/fonts/Quiet Sans Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Quiet Sans';
  src: url("../assets/fonts/Quiet Sans SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Quiet Sans';
  src: url("../assets/fonts/Quiet Sans Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Holidaze';
  src: url("../assets/fonts/Holidaze-Regular.otf") format('opentype');
  font-style: normal;
}